import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface Props {

}

interface State {

}

class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }


  render() {
    return (
      <div className='container-fluid '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='mt-4 mb-4'>Contact Us ↓</div>
              <img src="1683216556930.png" alt="logo" width={200} height={150} />
              <p>
                ST. MEERA CONVENT SCHOOL,<br />
                Balu Road, Kalayat(kaithal),<br />
                Kaithal, Haryana-136117.<br />
                Ph.No.: +91 70270-13003<br />Ph.No.: +91 70270-13004
              </p>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className='mt-4 mb-4'>Quick Links ↓</div>
              <div style={{  backgroundColor: "#587e80" ,color:'white', paddingLeft: 6 }}><Link className="dropdown-item" to={'/about'}>About School</Link></div>
              <div style={{  backgroundColor: "#587e80" ,color:'white', paddingLeft: 6 }}><Link className="dropdown-item mt-3 mb-3" to={'/documents'}>Documents and Information</Link></div>
              {/* <div style={{ backgroundColor: "#587e80" ,color:'white', paddingLeft: 6 }}><Link className="dropdown-item" to={'/result'}>Result and Academic</Link></div> */}
              <div style={{ backgroundColor: "#587e80" ,color:'white', paddingLeft: 6 }}><Link className="dropdown-item mt-3 mb-3" to={'/principalmsg'}>Principal Message</Link></div>
            </div>
            <div className='col-lg-4'>
              <div className='mt-4 mb-4'>Location Map ↓</div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3467.305516078153
          !2d76.34465967448907!3d29.652907637017318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3911e5ef3f61f
          f5d%3A0x71d4b85cec1a11b7!2sSt.%20Meera%20Convent%20School%2C%20Balu%20-%20Kalayat!5e0!3m2!1sen!2sin
          !4v1683344078245!5m2!1sen!2sin" width="320" height="200" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
