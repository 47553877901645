import React, { Component } from 'react';

interface Props {

}

interface State {

}

class Documents extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <>
                <div className='container'>
                    <div className='row' style={{ textAlign: 'center' }}><h3>APPENDIX -IX</h3></div>
                    <div className='row' style={{ textAlign: 'center' }}><h3>MANDATORY PUBLIC DISCLOSURE</h3></div>
                    <h5>A : GENERAL INFORMATION :</h5>
                    <div className='row table-responsive'>
                        <table className="table table-responsive table-bordered mt-3 mb-3  " style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">Sr.NO.</th>
                                    <th scope="col">INFORMATION </th>
                                    <th scope="col">DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>NAME OF THE SCHOOL</td>
                                    <td>ST. MEERA CONVENT SCHOOL</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>AFFILIATION NO.(IF APPLICABLE)</td>
                                    <td>532131</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>SCHOOL CODE (IF APPLICABLE)</td>
                                    <td>42163</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>COMPLETE ADDRESS WITH PIN CODE</td>
                                    <td>VILLAGE AND POST OFFICE BALU, TEHSIL
                                        KALAYAT, DISTRICT KAITHAL, KAITHAL, HARYANA -
                                        136117</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>PRINCIPAL NAME</td>
                                    <td>Mrs. PRAMILA</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>PRINCIPAL QUALIFICATION</td>
                                    <td>M.A B.ED</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>SCHOOL EMAIL ID</td>
                                    <td>sushilgill2512@gmail.com</td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
                                    <td>8684071871</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>B : DOCUMENTS AND INFORMATION:</h5>
                    <div className='row table-responsive'>
                        <table className="table table-responsive table-bordered mt-3 mb-3  " style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">Sr.NO.</th>
                                    <th scope="col">Documents/Information</th>
                                    <th scope="col">Links of Uploaded Documents on School Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY</td>
                                    <td><a href='https://www.stmeeraconventschool.com' target='_blank'>https://stmeeraconventschool.com</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/SOCIETY.pdf' target='_blank'>https://stmeeraconventschool.com/SOCIETY.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Objection_certificate.pdf' target='_blank'>https://stmeeraconventschool.com/Objection_certificate.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Recogination.pdf' target='_blank'>https://stmeeraconventschool.com/Recogination.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/BUILDING_SAFETY.pdf' target='_blank'>https://stmeeraconventschool.com/BUILDING_SAFETY.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>COPY OF VALID LAND CERTIFICATE ISSUED BY THE NATIONAL LAND CODE*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/certificate.pdf' target='_blank'>https://stmeeraconventschool.com/LAND_CERTIFICATE.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>WATER SAMPLE REPORT FROM PHED</td>
                                    <td><a href='https://www.stmeeraconventschool.com/water_sample.pdf' target='_blank'>https://stmeeraconventschool.com/water_sample.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Fire_Safety_Certificate.pdf' target='_blank'>https://stmeeraconventschool.com/Fire_Safety_Certificate.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">9</th>
                                    <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Self_Certificate.pdf' target='_blank'>https://stmeeraconventschool.com/Self_Certificate.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">10</th>
                                    <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
                                    <td><a href='https://www.stmeeraconventschool.com/PDFGallery_20240219_103344.pdf' target='_blank'>https://stmeeraconventschool.com/Sanitation_Certificate.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">11</th>
                                    <td>Affidavit / Undertaking</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Affidavit_Undertaking.pdf' target='_blank'>https://stmeeraconventschool.com/Affidavit_Undertaking.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">12</th>
                                    <td>ECS CERTIFICATE ISSUED BY THE BANK</td>
                                    <td><a href='https://www.stmeeraconventschool.com/ECS_CERTIFICATE_ISSUED_BY_THE_BANK.pdf' target='_blank'>https://stmeeraconventschool.com/ECS_CERTIFICATE_ISSUED_BY_THE_BANK.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">13</th>
                                    <td>LAND DOCUMENTS (SALE DEED/LEASE DEED/GIFT DEED/ALLOTMENT)</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Lease_Deed.pdf' target='_blank'>https://stmeeraconventschool.com/Lease_Deed.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">14</th>
                                    <td>FEE STRUCTURE OF THE SCHOOL*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/FEE_STRUCTURE_OF_THE_SCHOOL.pdf' target='_blank'>https://stmeeraconventschool.com/FEE_STRUCTURE_OF_THE_SCHOOL.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">15</th>
                                    <td>ANNUAL ACADEMIC CALENDER*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/ANNUAL_ACADEMIC_CALENDER.pdf' target='_blank'>https://stmeeraconventschool.com/ANNUAL_ACADEMIC_CALENDER.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">16</th>
                                    <td>SCHOOL MANAGEMENT COMMITTEE (SMC)*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/SCHOOL_MANAGEMENT_COMMITTEE.pdf' target='_blank'>https://stmeeraconventschool.com/SCHOOL_MANAGEMENT_COMMITTEE.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">17</th>
                                    <td>PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/PARENTS_TEACHERS_ASSOCIATION.pdf' target='_blank'>https://stmeeraconventschool.com/PARENTS_TEACHERS_ASSOCIATION.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">18</th>
                                    <td>EPF/ESIC CERTIFICATE    </td>
                                    <td><a href='https://www.stmeeraconventschool.com/EPFCODELETTER.pdf' target='_blank'>https://stmeeraconventschool.com/EPFCODELETTER.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">19</th>
                                    <td>SCHOOL COMMITTEES</td>
                                    <td><a href='https://www.stmeeraconventschool.com/SCHOOL_COMMITTEES.pdf' target='_blank'>https://stmeeraconventschool.com/SCHOOL_COMMITTEES.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">20</th>
                                    <td>STAFF DETAILS</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Staff_details.pdf' target='_blank'>https://stmeeraconventschool.com/Staff_details.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">21</th>
                                    <td>INFRASTRUCTURE DETAILS</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Infrastructure_Details.pdf' target='_blank'>https://stmeeraconventschool.com/Infrastructure_Details.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">22</th>
                                    <td>SLC/TC SAMPLE</td>
                                    <td><a href='https://www.stmeeraconventschool.com/SLC.pdf' target='_blank'>https://stmeeraconventschool.com/SLC.pdf</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>Note :</h5>
                    <p>THE SCHOOLS NEEDS TO UPLOAD THE SELF ATTESTED COPIES OF ABOVE LISTED DOCUMETNS BY
                        CHAIRMAN/MANAGER/SECRETARY AND PRINCIPAL. IN CASE, IT IS NOTICED AT LATER STAGE THAT UPLOADED
                        DOCUMENTS ARE NOT GENUINE THEN SCHOOL SHALL BE LIABLE FOR ACTION AS PER NORMS.
                    </p>
                    <h5>C : STAFF (TEACHING) :</h5>
                    <div className='container table-responsive'>
                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">S.NO.</th>
                                    <th scope="col">INFORMATION</th>
                                    <th scope="col">DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>PRINCIPAL</td>
                                    <td>Mrs. PRAMILA</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td colSpan={2}>
                                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                                            <tbody>
                                                <tr>
                                                    <td>TOTAL NO. OF TEACHERS</td>
                                                    <td>32</td>
                                                </tr>
                                                <tr>
                                                    <td>PGT</td>
                                                    <td>8</td>
                                                </tr>
                                                <tr>
                                                    <td>TGT</td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>PRT</td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <td>NTT</td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>PTI</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>LIBRARIAN</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>TEACHERS SECTION RATIO</td>
                                    <td>1:1.5</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>DETAILS OF SPECIAL EDUCATOR</td>
                                    <td>Mr. Balvinder</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td> DETAILS OF COUNSELLOR AND WELLNESS TEACHER</td>
                                    <td>Mr. Vasudev</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>D : SCHOOL INFRASTRUCTURE:</h5>
                    <div className='container table-responsive'>
                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">S.NO.</th>
                                    <th scope="col">INFORMATION</th>
                                    <th scope="col">DETAILS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQ MTR)</td>
                                    <td>12140.64</td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ MTR)</td>
                                    <td>32 & 48</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQ MTR)</td>
                                    <td>6 & 56</td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>INTERNET FACILITY</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>NO. OF GIRLS TOILETS</td>
                                    <td>8</td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>NO. OF BOYS TOILETS</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
                                    <td><a href='https://www.youtube.com/watch?v=j4dXJw81lX0&t=45s&pp=ygUdc3QgbWVlcmEgY29udmVudCBzY2hvb2wgYmFsdSA%3D' target='_blank'>https://youTubeLink.com</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>RESULT CLASS: X</h5>
                    <div className='container table-responsive'>
                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">S.NO.</th>
                                    <th scope="col">YEAR</th>
                                    <th scope="col">NO. OF REGISTERED STUDENTS</th>
                                    <th scope="col">NO. OF STUDENTS PASSED</th>
                                    <th scope="col">PASS PERCENTAGE</th>
                                    <th scope="col">REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>2023-2024</td>
                                    <td>19</td>
                                    <td>19</td>
                                    <td>100%</td>
                                    <td>N.A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h5>RESULT CLASS: XII</h5>
                    <div className='container table-responsive'>
                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">S.NO.</th>
                                    <th scope="col">YEAR</th>
                                    <th scope="col">NO. OF REGISTERED STUDENTS</th>
                                    <th scope="col">NO. OF STUDENTS PASSED</th>
                                    <th scope="col">PASS PERCENTAGE</th>
                                    <th scope="col">REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>2023-2024</td>
                                    <td>14</td>
                                    <td>14</td>
                                    <td>100%</td>
                                    <td>N.A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default Documents;