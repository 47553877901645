
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import Header from './components/common/header';
import Footer from './components/common/footer';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import PrincipalMessage from './components/PrincipalMessage';
import Vission from './components/Vission';
import FeeStructure from './components/FeeStructure';
import Facilities from './components/Facilities';
import News from './components/News';
import PhotoGallery from './components/PhotoGallery';
import VedioGallery from './components/VedioGallery';
import CompetitionResult from './components/CompetitionResult';
import Activities from './components/Activities';
import GeneralInformation from './components/CBSE/GeneralInformation';
import Documents from './components/CBSE/Documents';
import Result from './components/CBSE/Result';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/principalmsg" element={<PrincipalMessage />} />
        <Route path="/vission" element={<Vission />} />
        <Route path="/feeStructure" element={<FeeStructure />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/news" element={<News />} />
        <Route path="/photoGallery" element={<PhotoGallery />} />
        <Route path="/vedioGallery" element={<VedioGallery />} />
        <Route path="/competitionsResult" element={<CompetitionResult />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/generalInformation" element={<GeneralInformation />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/result" element={<Result />} />
        <Route path="/*" element={<Documents />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
