import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface Props {

}

interface State {

}

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <>
                <div className='container-fluid img'>
                    <div className="container">

                    <div className='row img'>
                        <div className='col-lg-3'>
                            <img src="1683216556930.png" alt="logo" width={200} height={150} />
                        </div>
                        <div className='col-lg-9 mt-4 p-0'>
                            <h1><p><b>ST. MEERA CONVENT SCHOOL</b></p></h1><h3 style={{color:'GrayText'}}>KALAYAT(KAITHAL)</h3>
                        </div>
                    </div>
                    </div>
                </div>
                    <nav className="navbar fontSize2 navbar-expand-lg navbar-light pb-4 pt-4 " style={{backgroundColor:"#587e80"}}>
                        <ul className="navbar-nav">
                            <li><Link className="dropdown-item" to={'/'} >HOME</Link></li>
                            <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">ABOUT US</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className='dropdown-item fontSize' to={'/about'}>About School</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/principalmsg'}>Principal Message</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/vission'}>Vision And Mission</Link></li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">ADMISSION</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><a href='https://www.stmeeraconventschool.com/Fee_Structure.pdf' target='_blank' className="dropdown-item fontSize" >Fee Structure</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">FACILITIES</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item fontSize" to={'/'}>Our Facilities</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">GALLERY</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item fontSize" to={'/'}>News</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/photoGallery'}>Photo Gallery</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/'}>Video Gallery</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/'}>Competitions Results</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">ACTIVITIES</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item fontSize" to={'/'}>Co-Curricular Activities</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li><Link className="dropdown-item ms-4" to={'/documents'}>CBSE MANDATORY PUBLIC DISCLOSURE</Link></li>
                            {/* <li>
                                <div className="dropdown">
                                    <li className='ms-4' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">CBSE MANDATORY PUBLIC DISCLOSURE</li>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li><Link className="dropdown-item fontSize" to={'/'}>General Information</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/documents'}>Documents and Information</Link></li>
                                        <li><Link className="dropdown-item fontSize" to={'/result'}>Result and Academic</Link></li>
                                    </ul>
                                </div>
                            </li> */}
                        </ul>
                    </nav>
                <hr className='mt-2' />
            </>
        );
    }
}

export default Header;
