import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BsBusFront } from "react-icons/bs";
import {FaUniversity,FaUsers,FaPencilAlt}  from "react-icons/fa";

interface Props {

}

interface State {

}

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <>
        <div className='container'>
          <div className='row'> </div>
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="DSC_2033(1)(1).jpg" className="d-block w-100" height={500} alt="..." />
              </div>
              <div className="carousel-item ">
                <img src="DSC_2166(1)(1).jpg" className="d-block w-100" height={500} alt="..." />
              </div>
              <div className="carousel-item">
                <img src="DSC_3093(1)(1).jpg" className="d-block w-100" height={500} alt="..." />
              </div>
              <div className="carousel-item">
                <img src="IMG19.jpeg" className="d-block w-100" height={500} alt="..." />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className="row row-xs-12 justify-content-center">
            <div className='col-lg-5 col-xm-12 me-2 mt-4 mb-4'>
              <div className="card bg-light" style={{ height: "20", fontSize: 12 }}>
                <h5 className="card-title mt-3 ms-3">About Our School</h5>
                <hr />
                <span className="text-center">
                  <img src="DSC_2035 (1).jpg" className="card-img-top rounded-circle shadow-4-strong ps-5 pe-5" style={{ width: "200px", height: "105px" }} alt="..." />
                </span>
                <div className="card-body">
                  <p className="card-text">Welcome to St. Meera Convent School, a leading educational institution that aims to provide quality education to students from all walks of life...<Link to={"/about"} className="btn btn-success">Read More</Link></p>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-xm-12 me-2 mt-4 mb-4'>
              <div className="card bg-light" style={{ height: "20", fontSize: 12 }}>
                <h5 className="card-title mt-3 ms-3">Principal's Message</h5>
                <hr />
                <span className="text-center">
                  <img src="IMG1.jpeg" className="card-img-top rounded-circle shadow-4-strong ps-5 pe-5" style={{ width: "200px" }} alt="..." />
                </span>
                <div className="card-body">
                  <p className="card-text">It is my great pleasure to welcome you to St. Meera Convent School, where we strive to provide an outstanding education for all students....<Link to={"/principalmsg"} className="btn btn-success">Read More</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid countercss">
          <div className="container pt-5 pb-5">
            <div className="row" >
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="mu-abtus-counter-single">
                  <FaUniversity  className='mb-3' size={"70px"}/>
                  <h2 className="counter">4</h2>
                  <p>YEAR OF EXCELLENE</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="mu-abtus-counter-single">
                  <FaUsers className='mb-3' size={"70px"}/>
                  <h2 className="counter">1000</h2>
                  <p>STUDENTS</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="mu-abtus-counter-single">
                <BsBusFront className='mb-3' size={"70px"}/>
                  <h2 className="counter">10</h2>
                  <p>TRANSPORT</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="mu-abtus-counter-single no-border">
                  <FaPencilAlt className='mb-3' size={"70px"}/>
                  <h2 className="counter">40</h2>
                  <p>Teachers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;