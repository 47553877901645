import React, { Component } from 'react';

interface Props {

}

interface State {

}

class Vission extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }


  render() {
    return (
        <div className='Container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 bg-light p-5'>
              <h3>Our vision</h3>
              <p style={{color:"red",fontSize:17}}>
                St. Meera, since, inception has successfully come up to the expectation of the society and parents. The curriculum and teaching as well as sports pedagogy are designed keeping in mind the needs of the present time’s atmosphere as well as the demand of the future. We at St. Meera, guide the children how to think and connect their learning and sports experience with the real-life situations. We strive to provide all necessary inputs for academic, sports, intellectual, social and emotional development of children. Our aim is to encourage learning by making the curriculum as interactive as possible, where children can learn by doing and develop their academic future as well sports future. Our vision is to mould every kid into a responsible citizen of the society on which, parents, society and nation should be proud.
              </p>
            </div>
          </div>
        </div>
    );
  }
}

export default Vission;