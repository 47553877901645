import React, { Component } from 'react';

interface Props {

}

interface State {

}

class About extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }


  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <h5>About School</h5>
            <img src='DSC_2035 (1).jpg' className='w-100 pt-3 pb-3' alt='about photo' height={600} />
          </div>
          <div className='col-lg-6 pt-5'>

            <p style={{ fontSize: 14 }}>
              Welcome to St. Meera Convent School, a leading educational institution that aims to provide quality education to students from all walks of life. Established in [year], we have been dedicated to creating a learning environment that fosters academic excellence, personal growth, and social responsibility.<br /><br />

              At St. Meera Convent School, we believe in equipping our students with the knowledge, skills, and values they need to succeed in the 21st century. Our curriculum is designed to meet the diverse needs and interests of our students while ensuring that they meet the academic standards set by the government.<br /><br />

              Our team of experienced and highly qualified teachers is committed to providing personalized attention to every student, guiding them to reach their full potential. We believe in creating a student-centered learning environment where students are encouraged to ask questions, think critically, and collaborate with their peers.<br /><br />

              Our facilities are state-of-the-art, providing students with access to the latest technology and resources needed to enhance their learning experience. We have a well-equipped library, science and computer labs, and sports facilities to support students' academic and extracurricular pursuits.<br /><br />

              At St. Meera Convent School, we are committed to fostering a culture of respect, inclusivity, and diversity. We celebrate our differences and encourage our students to appreciate and embrace the diversity of the world around them.<br />

              Thank you for considering St. Meera Convent School for your child's education. We look forward to partnering with you on this journey of learning and growth.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;