import React, { Component } from 'react';

interface Props {

}

interface State {

}

class PhotoGallery extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  render() {
    return (
      <>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card">
              <img src="IMG2.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG3.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG4.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG5.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG6.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG7.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG8.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG9.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG10.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG11.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG12.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG13.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG14.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG15.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG16.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG17.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG18.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG19.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src="IMG20.jpeg" className="card-img-top" alt="..." />
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-2">
            <button className='btn btn-primary mt-2 mb-2' onClick={this.handleClick}>Scrool To Top</button>
          </div>
        </div>
      </>
    );
  }
}

export default PhotoGallery;