import React, { Component } from 'react';

interface Props {

}

interface State {

}

class PrincipalMessage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }


  render() {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <h5>Principal Message</h5>
            <img src='1683216556930.png' className='w-100 pt-3 pb-3' alt='about photo' height={600} />
          </div>
          <div className='col-lg-6 pt-5'>
            <p style={{ fontSize: 14 }}>
              Dear Students, Parents, and Staff,<br/><br/>

              It is my great pleasure to welcome you to St. Meera Convent School, where we strive to provide an outstanding education for all students. Our school is committed to academic excellence, as well as the social and emotional well-being of our students.<br/><br/>

              St. Meera Convent School, we believe that each student is unique and has their own set of strengths and challenges. It is our responsibility as educators to provide a safe and inclusive environment that promotes learning, growth, and achievement for all.<br/><br/>

              We have an exceptional team of teachers and staff who are dedicated to creating a dynamic and engaging learning environment. We believe in using innovative teaching methods, integrating technology into the classroom, and fostering creativity and critical thinking skills.<br/><br/>

              We also recognize the importance of a strong partnership between the school, parents, and the community. We encourage and welcome parental involvement in all aspects of school life, from volunteering to attending school events and supporting their children's education at home.<br/><br/>

              At St. Meera Convent School, we are committed to instilling a love of learning and a sense of social responsibility in our students. We believe that education is not just about acquiring knowledge, but also about developing values and character that will serve our students throughout their lives.<br/><br/>

              Thank you for choosing St. Meera Convent School for your child's education. We look forward to working with you to create a bright future for our students.<br/><br/>

              Sincerely,
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrincipalMessage;