import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface Props {

}

interface State {
    
}

class Result extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            
        };
    }
    render() {
        return (
                <>
                    <div className='container table-responsive'>
                        <table className="table table-bordered mt-3 mb-3" style={{ fontSize: 15 }}>
                            <thead>
                                <tr>
                                    <th scope="col">S.NO.</th>
                                    <th scope="col">Documents/Information</th>
                                    <th scope="col">Links of Uploaded Documents on School Website</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>FEE STRUCTURE OF THE SCHOOL</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Fee_Structure.pdf' target='_blank'>https://stmeeraconventschool.com/Fee_Structure.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>ANNUAL ACADEMIC CALENDER</td>
                                    <td><a href='https://www.stmeeraconventschool.com/Academic_Calender.pdf' target='_blank'>https://stmeeraconventschool.com/Academic_calender.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/SMC.pdf' target='_blank'>https://stmeeraconventschool.com/SMC.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS*</td>
                                    <td><a href='https://www.stmeeraconventschool.com/PTA.pdf' target='_blank'>https://stmeeraconventschool.com/PTA.pdf</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>LAST THREE-YEARS RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY</td>
                                    <td><Link to={'/'}></Link></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
        );
    }
}

export default Result;