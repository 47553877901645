import React, { Component } from 'react';

interface Props {
  
}

interface State {
  
}

class Facilities extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      
    };
  }

  
  render() {
    return (
      <div>
        <h1>Hello from Our Facilities</h1>
      </div>
    );
  }
}

export default Facilities;